import { useContractCalls, useContractFunction } from '@usedapp/core'
import { Contract } from '@ethersproject/contracts'
import { utils, BigNumber } from 'ethers'
import { call, makeUseBalance } from './hooks'
import dask from './abis/DASKa.json'
import { daskContractAddress, vaultContractAddress } from '../config'

const daskInterface = new utils.Interface(dask.abi)
const daskContract = new Contract(daskContractAddress, daskInterface)

const daskCall = call(daskInterface, daskContractAddress)

const useContractState = () => {
    const [price, MAX_PER_MINT, MAX_TOKENS, isRevealed, publicSaleStarted, presaleStarted, totalSupply] =
        useContractCalls([
            daskCall('price'),
            daskCall('MAX_PER_MINT'),
            daskCall('MAX_TOKENS'),
            daskCall('isRevealed'),
            daskCall('publicSaleStarted'),
            daskCall('presaleStarted'),
            daskCall('totalSupply'),
        ]
        ) ?? []
    return {
        price: price?.[0],
        MAX_PER_MINT: MAX_PER_MINT?.[0],
        MAX_TOKENS: MAX_TOKENS?.[0],
        isRevealed: isRevealed?.[0],
        publicSaleStarted: publicSaleStarted?.[0],
        presaleStarted: presaleStarted?.[0],
        totalSupply: totalSupply?.[0],
    }
}

const useDaskBalance = makeUseBalance(daskCall)

const useAccountTokens = (address, balanceValid) => {
    const numTokensBN = useDaskBalance(address)
    const numTokens = BigNumber.isBigNumber(numTokensBN) && numTokensBN.toNumber()
    const calls = (balanceValid && address && numTokens > 0) ? [...Array(numTokens).keys()].map(i => daskCall('tokenOfOwnerByIndex', [address, i])) : []
    const tokens = useContractCalls([
        ...calls
    ]).filter(Boolean) || []
    return tokens && tokens.map(([t]) => BigNumber.isBigNumber(t) ? t.toNumber() : t)
}

const useIsVaultApproved = (address) => {
    const [isApproved] = useContractCalls([
        address && daskCall('isApprovedForAll', [address, vaultContractAddress])
    ])
    return isApproved?.[0]
}

const useApproveVault = () => {
    const fn = useContractFunction(daskContract, 'setApprovalForAll')
    return { setApprovalForAll: () => fn.send(vaultContractAddress, true), setApprovalForAllState: fn.state, setApprovalForAllEvents: fn.events }
}

export { useContractState, useDaskBalance, useAccountTokens, useIsVaultApproved, useApproveVault }