import { ChainId } from '@usedapp/core'
import { Mainnet, Rinkeby } from '@usedapp/core'

const SupportedChain = Mainnet

const config = {
  networks: [SupportedChain],
  readOnlyChainId: SupportedChain.chainId,
  readOnlyUrls: {
    [ChainId.Mainnet]: 'https://eth-mainnet.alchemyapi.io/v2/j-laCoDEuVRQkXht0rsTRRhogpfwXG5t',
    [ChainId.Rinkeby]: 'https://rinkeby.infura.io/v3/4b72c849071a41dbaf5eee4355478564',
  },
  multicallAddresses: {
    [ChainId.Mainnet]: Mainnet.multicallAddress,
    [ChainId.Rinkeby]: Rinkeby.multicallAddress,
  }
}

const daskContractAddress = '0x19d84b2a4b21910339af097a1bddb48682d6f47d'
const klubContractAddress = '0xa0DB234a35AaF919b51E1F6Dc21c395EeF2F959d'
const vaultContractAddress = '0x2C941B11602686d0Ae5d9F8F12dB47766B8EDC9E'

export { config, daskContractAddress, klubContractAddress, vaultContractAddress }
