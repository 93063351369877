import { useContractCalls } from '@usedapp/core'

const call = (contractInterface, address) => (name, args) => {
    return {
        abi: contractInterface,
        address: address,
        method: name,
        args: args ?? [],
    }
}

const makeUseBalance = (callFn) => (address) => {
    const [balance] = useContractCalls(
        [address && callFn('balanceOf', [address])]
    ) ?? []
    return balance?.[0]
}

export { call, makeUseBalance }