import { BigNumber } from 'ethers'
import metadata from './metadata.json'

const tokenDataFromIds = ({ ids, isStaked }) => {
    const tokens = ids && ids.length > 0 && ids.map(idbn => {
        const id = BigNumber.isBigNumber(idbn) ? idbn.toNumber() : idbn
        const token = metadata[id]
        token.id = id
        token.isStaked = isStaked
        return token
    })
    return tokens || []
}

export { tokenDataFromIds }