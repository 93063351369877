// import { Contract } from '@ethersproject/contracts'
import { utils } from 'ethers'
import { call, makeUseBalance} from './hooks'
import klub from './abis/Klub.json'
import { klubContractAddress } from '../config'

const klubInterface = new utils.Interface(klub.abi)
// const klubContract = new Contract(klubContractAddress, klubInterface)

const klubCall = call(klubInterface, klubContractAddress)


const useKlubBalance = makeUseBalance(klubCall)

export { useKlubBalance }